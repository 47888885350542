<template>
  <section class="preview" :class="{'new': isNew}">
    <b-row>
      <b-col>
        <p class="times">{{homework.datetime | moment('HH:mm, DD MMM YYYY')}}</p>
        <div class="icons">
          <homeSvg />
          <b class="icon icon-new" v-if="isNew">NEW</b>
          <b class="icon icon-done" v-if="isDone">✔ Завершено</b>
          <b class="icon icon-verified" v-if="isReviewed">✔ Проверено</b>
        </div>
        <h1>{{title}}</h1>
        <button class="btn-link" @click="doHomework(p)">Начать</button>
      </b-col>
    </b-row>
    <div class="image-container" :style="{ backgroundImage: 'url(/images/test_homework.jpeg)' }"></div>
  </section>
</template>

<script>
import homeSvg from '@/assets/svg/homework.svg'

export default {
  name: "HomeworkPreview",
  components: {
    homeSvg,
  },
  props: {
    homework: { type: Object },
  },
  computed: {
    title() {
      return this.homework.material ? this.homework.material.title : this.homework.id
    },
    isNew() {
      return this.homework.is_new || false;
    },
    isDone() {
      return this.homework.pivot.homework_done === 1
    },
    isReviewed() {
      return this.homework.pivot.homework_reviewed === 1
    }
  },
  methods: {
    doHomework() {
      this.$router.push({
        name: 'Lesson',
        params: {id: this.homework.id, code: this.homework.code, homework: 'homework'}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.preview {
  border-radius: 20px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.preview.new {
  background: #E6F7FF;
}

.times {
  font-size: 14px;
  margin: 8px 0;
  color: #333;
}

.icons {
  margin: 5px 0;
  display: flex;
  gap: 0 6px;
  align-items: center;
}

.icon {
  padding: 4px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 7px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-new {
  background: #56CCF2;
  color: #fff;
}

.icon-done {
  background: #27AE60;
  color: #fff;
}

.icon-verified {
  background: #E0FBEB;
  color: #27AE60;
}

h1 {
  font-size: 22px;
  margin: 10px 0;
  font-weight: bold;
  color: #333;
}

.btn-link {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: #00A3FF;
  cursor: pointer;
  padding: 0;
  margin: 15px 0;
}

.image-container-col {
  display: flex;
  justify-content: flex-end;
}

.image-container {
  width: 45%;
  height: 130%;
  transform: rotate(10deg); /* Наклон */
  background-position: left top;
  position: absolute;
  right: -20px;
}
</style>